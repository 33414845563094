<template>
	<div>
		<!-- 轮播图 -->
		<el-carousel :height="imgHeight+'px'" :interval="5000" style="z-index: -1;">
			<el-carousel-item v-for="(item,index) in bannerlist" :key="index">
				<img ref="imgHeight" style="width:100%;" :src="item.imageurl" mode="widthFix" />
			</el-carousel-item>
		</el-carousel>
		<!-- 行业痛点 -->
		<div class="title">行业痛点</div>
		<div class="box1 fcs">
			<div class="box1_item" v-for="(item,index) in tdlist" :key="index">
				<img class="box1_item_img" :src="item.imageurl" alt="">
				<div class="box1_item_t">{{item.title}}</div>
			</div>
		</div>
		<!-- 方案场景 -->
		<div class="title">方案场景</div>
		<div class="box2">
			<div class="box2_item fcs" v-for="(item,index) in cjlist" :key="index">
				<img class="box2_item_img" :src="item.imageurl" alt="">
				<div style="width: 300px;height: 160px;">
					<div class="box2_item_t">{{item.title}}</div>
					<div class="box2_item_p">{{item.describe}}</div>
				</div>
			</div>
		</div>
		<!-- 适用产品 -->
		<div class="title">适用产品</div>
		<div class="box3 fcs">
			<div class="box3_item" v-for="(item,index) in cplist" :key="index">
				<img class="box3_item_img" :src="item.imageurl" alt="">
				<div class="box3_item_t">{{item.title}}</div>
			</div>
		</div>
		<!-- 方案优势 -->
		<div class="title">方案优势</div>
		<div class="box4">
			<div class="box4_item" v-for="(item,index) in yslist" :key="index">
				<img class="box4_item_img" :src="item.imageurl" alt="">
				<div class="box4_item_t">{{item.title}}</div>
				<div class="box4_item_p">{{item.describe}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				imgHeight: '', //图片高度
				bannerlist: [], //轮播图列表
				tdlist: [], //行业痛点列表
				cjlist: [], //方案场景列表
				cplist: [], //适用产品列表
				yslist: [] //方案优势列表
			}
		},
		mounted() {
			this.getbanner();
			// 监听窗口变化，使得轮播图高度自适应图片高度
			window.addEventListener("resize", () => {
				this.getbanner();
			}, false);
		},
		destroyed() { //切换页面后销毁监听窗口事件
			window.removeEventListener("resize", this.getbanner, false);
		},
		created() {
			this.gettd();
			this.getcj();
			this.getcp();
			this.getys();
		},
		methods: {
			//获取轮播图 
			getbanner() {
				let data = {
					mark: 'option01'
				}
				this.$api.home.getAdsByMark(data).then(res => {
					if (res.code == 1) {
						this.bannerlist = res.data.data
						//获取到图片数据之后
						this.$nextTick(function() {
							// 获取窗口宽度*图片的比例，定义页面初始的轮播图高度 width=1920,height=480 图片的默认宽高
							this.imgHeight = document.body.clientWidth * 500 / 1920
						});
					}
				})
			},
			//获取行业痛点
			gettd() {
				let data = {
					mark: 'option02'
				}
				this.$api.home.getAdsByMark(data).then(res => {
					if (res.code == 1) {
						this.tdlist = res.data.data;
					}
				})
			},
			//获取方案场景
			getcj() {
				let data = {
					mark: 'option03'
				}
				this.$api.home.getAdsByMark(data).then(res => {
					if (res.code == 1) {
						this.cjlist = res.data.data;
					}
				})
			},
			//获取适用产品
			getcp() {
				let data = {
					mark: 'option04'
				}
				this.$api.home.getAdsByMark(data).then(res => {
					if (res.code == 1) {
						this.cplist = res.data.data;
					}
				})
			},
			//获取方案优势
			getys() {
				let data = {
					mark: 'option05'
				}
				this.$api.home.getAdsByMark(data).then(res => {
					if (res.code == 1) {
						this.yslist = res.data.data;
					}
				})
			}
		}
	}
</script>

<style scoped>
	.title {
		width: 200px;
		margin: 50px auto;
		font-size: 32px;
		text-align: center;
	}

	.box1 {
		width: 1200px;
		margin: 0 auto;
		margin-bottom: 100px;
	}

	.box1 .box1_item {
		font-size: 24px;
		text-align: center;
	}

	.box1 .box1_item .box1_item_img {
		height: 71px;
		margin-bottom: 10px;
	}

	.box2 {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 100px;
	}

	.box2 .box2_item {
		width: 500px;
		margin-bottom: 50px;
	}

	.box2 .box2_item .box2_item_img {
		width: 160px;
		height: 160px;
		margin-bottom: 10px;
	}

	.box2 .box2_item .box2_item_t {
		display: inline-block;
		padding: 0 16px;
		line-height: 46px;
		height: 46px;
		background: #006DFF;
		border-radius: 45px;
		font-size: 16px;
		color: #ffffff;
		margin-bottom: 10px;
	}

	.box2 .box2_item .box2_item_p {
		font-size: 18px;
		color: #333333;
		opacity: 0.8;
	}

	.box3 {
		width: 1200px;
		margin: 0 auto;
		margin-bottom: 100px;
	}

	.box3 .box3_item {
		font-size: 24px;
		text-align: center;
	}

	.box3 .box3_item .box3_item_img {
		height: 76px;
		margin-bottom: 10px;
	}

	.box4 {
		width: 1220px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 100px;
	}

	.box4 .box4_item {
		width: 390px;
		height: 400px;
		background: #FFFFFF;
		margin: 15px 8px;
		border: 1px solid #CCCCCC;
		position: relative;
		box-sizing: border-box;
		padding: 35px;
	}

	.box4 .box4_item .box4_item_img {
		width: 97px;
		position: absolute;
		top: 33px;
		right: 60px;
	}

	.box4 .box4_item .box4_item_t {
		font-size: 22px;
		color: #006DFF;
		margin-top: 130px;
	}

	.box4 .box4_item .box4_item_p {
		font-size: 20px;
		color: #24272C;
		opacity: 0.8;
		margin-top: 14px;
	}
</style>